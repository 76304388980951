<template>
  <v-alert
    type="warning"
    variant="tonal"
    :icon="icons.sad"
    border="top"
  >
    <v-alert-title>
      <p style="text-align: left">
        Oeps...
        <br/>
        Er is iets fout gelopen.
      </p>
    </v-alert-title>
    <ReloadCountdown 
      class="mt-5"
      @reload="reloadAction"
    />
  </v-alert>
</template>

<script>
import { mdiEmoticonSadOutline } from "@mdi/js"
import ReloadCountdown from "./ReloadCountdown.vue";
 
export default {
  name: 'ErrorMessage',
  components: { ReloadCountdown },
  data: () => ({
    icons: {
      sad: mdiEmoticonSadOutline,
    }
  }),
  methods: {
    reloadAction() {
      if(window.location.search.includes('autoplay=true')) {
        window.location.reload()
      } else {
        window.location.replace(window.location.origin + '?autoplay=true')
      }
    }
  }
}
</script>

<style>

</style>