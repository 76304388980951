<template>
  <v-row 
    justify="center"
  >
    <v-col 
      cols="3"
      sm="2"
      align-self="center"
    >
      <IconButton
        :icon="icons.min"
        @click="volumeMin"
      />
    </v-col>

    <v-col 
      cols="6"
      sm="8"
      align-self="center"
    >
      <v-slider
        v-model="volume"
        :step="step"
        :min="VOLUME_MIN"
        :max="VOLUME_MAX"
        thumb-label
        thumb-color="secondary"
        hide-details
        @update:model-value="volumeChanged"
        color="primary"
      />
    </v-col>

    <v-col 
      cols="3"
      sm="2"
      align-self="center"
    >
      <IconButton
        :icon="icons.plus"
        @click="volumePlus"
      />
    </v-col>
  </v-row>

</template>

<script>
import { mdiVolumeMinus, mdiVolumePlus } from "@mdi/js";
import IconButton from "./IconButton.vue";

export default {
  name: "VolumeControl",
  components: { 
    IconButton
  },
  props: ["value"],
  emits: ['change'],
  data: () => ({
    VOLUME_MIN: 0,
    VOLUME_MAX: 100,
    step: 1,
    volume: 100,
    icons: {
        min: mdiVolumeMinus,
        plus: mdiVolumePlus,
    },
  }),
  watch: {
    value(newValue) {
      this.volume = newValue;
    }
  },
  methods: {
    volumeMin() {
      let newVolume = this.volume - (this.step * 5);
      this.$emit("change", newVolume < this.VOLUME_MIN ? this.VOLUME_MIN : newVolume);
    },
    volumePlus() {
      let newVolume = this.volume + (this.step * 5);
      this.$emit("change", newVolume > this.VOLUME_MAX ? this.VOLUME_MAX : newVolume);
    },
    volumeChanged(value) {
      this.$emit("change", value);
    },
  },
}
</script>

<style>

</style>