// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'

// Vuetify
import { createVuetify } from 'vuetify'

import { aliases, mdi } from 'vuetify/iconsets/mdi-svg'


const CorneelTheme = {
  dark: false,
  colors: {
    background: '#fde9e7',
    surface: '#fff',
    primary: '#f46757',
    secondary: '#fcd4cf',
    error: '#f33f87',
    info: '#6757f4',
    success: '#0cc078',
    warning: '#c0780c',
    'popup': '#b0bec5',       // blue-grey lighten-3
    'popup-darken': '#90a4ae' // blue-grey lighten-2
  }
}

export default createVuetify(
  // https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
  {
    icons: {
      defaultSet: 'mdi',
      aliases,
      sets: {
        mdi,
      },
    },
    theme: {
      defaultTheme: 'CorneelTheme',
      themes: {
        CorneelTheme,
      }
    }
  }
)
