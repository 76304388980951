<template>
  <v-row 
    justify="center"
  >
    <v-col
      align="center" 
      cols="12" sm="8" md="6"
    >
      <v-alert
        type="warning"
        variant="tonal"
        title="Buiten dienst"
        :icon="icons.inactive"
        border="top"
      >
        <v-divider class="my-2 bg-white"></v-divider>
        <p>
          De livestream is momenteel niet actief.
        </p>
        <v-icon :icon="icons.sad" class="my-5"/>
        <p>
          Probeer later opnieuw (tijdens de zenduren).
        </p>
      </v-alert>
    </v-col>
  </v-row>
</template>

<script>
import { mdiBroadcastOff, mdiEmoticonSadOutline  } from "@mdi/js";

export default {
  name: 'InactiveMessage',
  data: () => ({
    icons: {
      inactive: mdiBroadcastOff,
      sad: mdiEmoticonSadOutline
    },
  })
};
</script>

<style>

</style>