<template>
  <v-row 
    v-if="!show" 
    justify="center"
  >
    <v-col
      align="center" 
      cols="12" sm="8" md="6"
    >
      <v-btn
        :prepend-icon="icons.install"
        color="success"
        @click="show=true"
      >
        Installeer als applicatie
      </v-btn>
    </v-col>
  </v-row>
  <v-dialog 
    v-else
    v-model="show"
  >
    <v-alert 
      type="success"
      color="popup"
      title="Installeer als applicatie"
      :icon="icons.install"
    >
      <v-divider class="my-2 bg-black"></v-divider>
      <p>
        Deze site kan als applicatie worden geïnstalleerd.
      </p>
      
      <p class="mt-5">Voordelen:</p>
      <ul class="pl-10">
        <li>Opent in een afzonderlijk venster</li>
        <li>Livestream is sneller bereikbaar</li>
      </ul>

      <v-row class="mt-5" justify="center">
        <v-col cols="12" sm="6" align="center">
          <v-btn
            color="popup-darken"
            variant="flat"
            @click="show=false"
          >
            Niet nu
          </v-btn>
        </v-col>
        <v-col cols="12" sm="6" align="center">
          <v-btn
            color="success"
            variant="flat"
            @click="accept"
          >
            Installeer
          </v-btn>
        </v-col>
      </v-row>
    </v-alert>
  </v-dialog>
</template>

<script>
import { mdiDownloadOutline } from "@mdi/js";

export default {
  name: 'InstallMessage',
  emits: ['accept'],
  data: () => ({
    show: true,
    icons: {
      install: mdiDownloadOutline
    },
  }),
  methods: {
    accept() {
      this.$emit('accept')
    },
  },
};
</script>

<style>

</style>