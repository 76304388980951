<template>
  <v-row 
    justify="center"
  >
    <v-col
      align="center" 
      cols="12" sm="8" md="6"
    >
      <v-alert
        type="error"
        variant="tonal"
        title="Geen verbinding!"
        :icon="icons.offline"
        border="top"
      >
        <v-divider class="my-2 bg-white"></v-divider>
        <p>
          Kan geen verbinding maken met de livestream.
        </p>
        <p>
          Controleer uw internetconnectie.
        </p>
        <v-row class="mt-5" justify="center">
          <v-col cols="12" sm="6" align="center">
            <v-btn
              color="error"
              variant="tonal"
              @click="reload"
            >
            Probeer opnieuw
            </v-btn>
          </v-col>
        </v-row>
      </v-alert>
    </v-col>
  </v-row>
</template>

<script>
import { mdiWebOff } from "@mdi/js";

export default {
  name: 'OfflineMessage',
  emits: ['retry'],
  data: () => ({
    icons: {
      offline: mdiWebOff
    },
  }),
  methods: {
    reload() {
      this.$emit('retry')
    },
  },
};
</script>

<style>

</style>