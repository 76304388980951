<template>
  <v-row 
    v-if="hide" 
    justify="center"
  >
    <v-col
      align="center" 
      cols="12" sm="8" md="6"
    >
      <v-btn
        :prepend-icon="icons.update"
        color="info"
        @click="hide=false"
      >
        Update beschikbaar
      </v-btn>
    </v-col>
  </v-row>
  <v-dialog 
    v-else
    :model-value="true"
    persistent
  >
    <v-alert 
      type="info"
      title="Er is een update beschikbaar!"
      :icon="icons.update"
    >
      <v-divider class="my-2 bg-white"></v-divider>
      <p>
        Bij het herladen van deze pagina wordt de nieuwe versie in gebruik genomen.
      </p>
      <v-row class="mt-5" justify="center">
        <v-col cols="12" sm="6" align="center">
          <v-btn
            color="white"
            variant="outlined"
            @click="accept"
          >
          Update nu
          </v-btn>
        </v-col>
        <v-col cols="12" sm="6" align="center">
          <v-btn
            color="white"
            variant="outlined"
            @click="hide=true"
          >
          later
          </v-btn>
        </v-col>
      </v-row>
    </v-alert>
  </v-dialog>
</template>

<script>
import { mdiProgressDownload } from "@mdi/js";

export default {
  name: 'UpdateMessage',
  emits: ['accept'],
  data: () => ({
    hide: false,
    icons: {
      update: mdiProgressDownload
    },
  }),
  methods: {
    accept() {
      this.$emit('accept')
    },
  },
};
</script>

<style>

</style>