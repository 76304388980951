<template>
  <v-row align="center" class="my-1">
    <v-col cols="12">
      <p :style="timerStyle">
        Pagina wordt herladen in {{timer}} seconde{{timer>1?'n':''}}.
      </p>
      <p v-if="cancelled">
        Automatisch herladen geannulleerd.
      </p>
    </v-col>

    <v-col>
      <v-btn
        @click="reload"
      >
        Herlaad nu
      </v-btn>
    </v-col>
    <v-col>
      <v-btn
        @click="cancel"
        :disabled="cancelled"
      >
        Annulleer
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'ReloadCountdown',
  emits: ['reload'],
  props: {
    time: {
      type: Number,
      required: false,
      default: 30
    }
  },
  data: () => ({
    interval: null,
    timer: null,
    cancelled: false
  }),
  computed: {
    timerStyle() {
      return {
        'text-decoration': this.cancelled?'line-through':''
      }
    }
  },
  mounted() {
    this.timer = this.time
    this.interval = setInterval(this.decrement, 1000)
  },
  methods: {
    decrement() {
      if(--this.timer <= 0) {
        clearInterval(this.interval)
        this.reload()
      }
    },
    reload() {
      this.$emit('reload')
    },
    cancel() {
      clearInterval(this.interval)
      this.cancelled = true
    }
  }
}
</script>

<style>

</style>