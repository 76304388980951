<template>
  <v-row 
    justify="center"
  >
    <v-col
      cols="8"
      sm="6"
      md="4"
      align="center"
    >
      <h1 
        class="my-5 text-primary"
      >
        LUISTER LIVE
        <v-tooltip
          activator="parent"
          location="bottom"
          :model-value="!isLive && isPlaying"
        >
          <v-alert
            type="warning"
            border="top"
            :icon="icons.alert"
          >
            U luistert uitgesteld!
          </v-alert>
        </v-tooltip>
      </h1>

      <ErrorMessage v-if="error"/>
      
      <AudioButtons
        v-if="!error"
        :loading="loading"
        :live="isLive"
        :playing="isPlaying"
        :played="hasPlayed"
        @play="usr_play"
        @pause="usr_pause"
        @stop="usr_stop"
        @live="usr_live"
      />
      <VolumeControl
        v-if="!error"
        :value="volume"
        @change="usr_volumechange"
      />
    </v-col>
  </v-row>
</template>

<script>
import { mdiClockAlertOutline } from "@mdi/js"

import ErrorMessage from "./ErrorMessage.vue";
import AudioButtons from "./AudioButtons.vue";
import VolumeControl from "./VolumeControl.vue";

export default {
  name: 'LivestreamControl',
  components: { ErrorMessage, AudioButtons, VolumeControl },
  props: {
    url: {
      type: String,
      required: true,
    }
  },
  data: () => ({
    audio_element: null,
    error: false,
    loading: false,
    hasPlayed: false,
    isPlaying: false,
    isLive: true,
    volume: 100,
    icons: {
      alert: mdiClockAlertOutline,
    }
  }),
  mounted() {
    if(window.location.search.includes('autoplay=true')) {
      this.usr_play()
    }
  },
  methods: {
    create_audio_element(startPlaying = false) {
      this.audio_element = new Audio(this.url)
      this.audio_element.id = 'audio'
      this.audio_element.addEventListener('loadeddata', this.audio_loadeddata)
      this.audio_element.addEventListener('play', this.audio_play)
      this.audio_element.addEventListener('pause', this.audio_pause)
      this.audio_element.addEventListener('volumechange', this.audio_volumechange)
      this.audio_element.addEventListener('error', this.audio_error)
      this.audio_element.style.display = 'none'
      this.$el.children[0].prepend(this.audio_element)

      this.usr_volumechange(this.volume)
      
      if(startPlaying) {
        this.audio_element.play()
      }
    },
    remove_audio_element() {
      if(this.audio_element) { 
        this.audio_element.removeEventListener('loadeddata', this.audio_loadeddata)
        this.audio_element.removeEventListener('play', this.audio_play)
        this.audio_element.removeEventListener('pause', this.audio_pause)
        this.audio_element.removeEventListener('volumechange', this.audio_volumechange)
        this.audio_element.removeEventListener('error', this.audio_error)
        this.audio_element.pause()
        this.audio_element.src = ''
        this.audio_element.remove()
        this.audio_element = null
      }
    },

    audio_loadeddata() {
      this.loading = false
      this.audio_volumechange()
    },
    audio_error() {
      this.error = true
    },
    audio_play() {
      this.hasPlayed = true
      this.isPlaying = true

      this.loading = false
    },
    audio_pause() {
      this.isPlaying = false
      this.isLive = false

      this.loading = false
    },
    audio_volumechange() {
      this.volume = 100 * this.audio_element.volume
    },
    usr_play() {
      if(!this.audio_element) {
        this.create_audio_element()
      }
      this.audio_element.play()

      this.loading = true
    },
    usr_pause() {
      this.audio_element.pause()

      this.loading = true
    },
    usr_stop() {
      this.remove_audio_element()
      this.isPlaying = false
      this.isLive = true
      this.hasPlayed = false
    },
    usr_live() {
      this.remove_audio_element()
      this.loading = true
      this.isLive = true
      this.create_audio_element(true)
    },
    usr_volumechange(value) {
      if(this.audio_element) { 
        this.audio_element.volume = 1.0 * value / 100
      } else {
        this.volume = value
      }
    }
  }
}
</script>

<style>
.v-tooltip .v-overlay__content {
  background: none;
}
</style>