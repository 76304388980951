<template>
  <v-btn
    :disabled="disabled"
    color="primary"
    fab
    icon
    height="fit-content" width="fit-content"
  >
    <v-icon
      :size="size"
      color="secondary"
      :icon="icon"
    />
  </v-btn>
</template>

<script>
export default {
  name: 'IconButton',
  props: {
    icon: {
      type: String,
      required: true
    },
    size: {
      type: String || Number,
      required: false,
      default: '100%'
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
  },
}
</script>
