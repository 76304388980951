<template>
  <v-app>
    <v-main>
      <v-container fluid class="fill-height">
        <SiteBanner/>

        <UpdateMessage 
          v-if="pwa.updateAvailable"
          @accept="update_now"
        />
        <InstallMessage
          v-else-if="installation"
          @accept="install_now"
        />

        <OfflineMessage
          v-if="offline"
          @retry="get_network_status"
        />
        <InactiveMessage 
          v-else-if="!stream.active"
        />
        <LivestreamControl
          v-else
          :url="stream.url"
        />

      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import axios from 'axios'
import { isValid_config } from './validation/validateJSON'

import SiteBanner from './components/SiteBanner.vue'
import OfflineMessage from './components/OfflineMessage.vue'
import UpdateMessage from "./components/UpdateMessage.vue"
import InstallMessage from './components/InstallMessage.vue'
import InactiveMessage from './components/InactiveMessage.vue'
import LivestreamControl from './components/LivestreamControl.vue'

export default {
  name: 'App',
  components: {
    SiteBanner,
    OfflineMessage,
    UpdateMessage,
    InstallMessage,
    InactiveMessage,
    LivestreamControl,
},
  data: () => ({
    installation: null,
    pwa: {
      registration: null,
      updateAvailable: false,
      isRefreshing: false,
    },
    offline: false,
    stream: {
      active: false,
      url: ""
    }
  }),
  created() {
    window.addEventListener('online', () => {
      this.get_network_status()
    });
    window.addEventListener('offline', () => {
      this.offline = true
    });
    document.addEventListener('swOffline', () => {
      this.offline = true;
    });

    window.addEventListener('beforeinstallprompt', (event) => {
      event.preventDefault()
      this.installation = event
    })
    window.addEventListener('appinstalled', () => {
      this.installation = null
    })

    document.addEventListener('swUpdated', (e) => {
      this.pwa.registration = e.detail;
      this.pwa.updateAvailable = true;
    }, { once: true });
    navigator.serviceWorker.addEventListener('controllerchange', () => {
      if (this.pwa.isRefreshing) return;
      this.pwa.isRefreshing = true;
      window.location.reload();
    });
  },
  mounted() {
    this.get_network_status()
  },
  methods: {
    update_now() {
      this.pwa.updateAvailable = false;
      if (this.pwa.registration && this.pwa.registration.waiting) {
        this.pwa.registration.waiting.postMessage({type:'SKIP_WAITING'});
      }
    },
    install_now() {
      this.installation.prompt()
    },
    get_network_status() {
      axios.get(
        '/api/config.json',
        {
          headers: {
            'Accept': 'application/json',
            'Cache-Control': 'no-cache',
            'Pragma': 'no-cache',
            'Expires': '0',
          }
        }
      ).then(res => {
        this.offline = false
        if(isValid_config(res.data)) {
          this.stream.active = res.data.active
          this.stream.url = res.data.streamURL
        }
      })
      .catch(() => {
        this.offline = true
      })
    }
  },
}
</script>
