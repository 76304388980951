<template>
  <v-row 
    justify="center"
  >
    <v-col
      cols="12" sm="8" md="6"
    >
      <v-img
        src="/img/static/corneel.png"
      />
    </v-col>
  </v-row>
</template>

<script>
export default {
    name: 'SiteBanner',
}
</script>
