<template>
  <v-container fluid>
    <v-row
      justify="center"
    >
      <v-col 
        cols="8"
        sm="6"
        lg="4"
        align-self="end"
        style="margin-bottom: 2.5vw;"
      >
        <IconButton
          @click="mainButtonClick"
          :icon="mainButtonIcon"
          :loading="loading"
        />
      </v-col>
    </v-row>

    <v-row 
      justify="center"
      style="margin-top: -15%"
      no-gutters
    >
      <v-col 
        cols="4"
        sm="3"
        lg="2"
      >
        <IconButton
          v-show="!loading"
          @click="stopButtonClick"
          :icon="icons.stop"
          :disabled="!played"
          :loading="loading"
        />
      </v-col>
      <v-col 
        cols="4"
        sm="3"
        lg="2"
      >
        <IconButton
          :icon="icons.empty"
          disabled
          style="opacity: 0;"
        />
      </v-col>
      <v-col 
        cols="4"
        sm="3"
        lg="2"
      >
        <IconButton
          v-show="!loading"
          @click="liveButtonClick"
          :icon="icons.live"
          :disabled="live"
          :loading="loading"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mdiPlayCircle, mdiPauseCircle, mdiStopCircle, mdiFastForward, mdiCheckboxBlankCircle } from "@mdi/js";
import IconButton from './IconButton.vue';

export default {
  name: 'AudioButtons',
  components: {
    IconButton
  },
  props: {
    playing: {
      type: Boolean,
      required: true,
      default: false,
    },
    played: {
      type: Boolean,
      required: true,
      default: false,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    live: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  emits: ['pause','play','stop','live'],
  data: () => ({
    icons: {
      play: mdiPlayCircle,
      pause: mdiPauseCircle,
      stop: mdiStopCircle,
      live: mdiFastForward,
      empty: mdiCheckboxBlankCircle
    },
  }),
  computed: {
    mainButtonIcon() {
      return this.playing ? this.icons.pause : this.icons.play
    }
  },
  methods: {
    mainButtonClick() {
      this.$emit(this.playing ? 'pause' : 'play')
    },
    stopButtonClick() {
      this.$emit('stop')
    },
    liveButtonClick() {
      this.$emit('live')
    },
  }
}
</script>